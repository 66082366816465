<template lang="pug">
.layout-container(
  :class="layoutClass"
)
  .transparent-block(v-show="layoutClass === 'is-profile'")
  navbar-mobile.mobile-layout(v-if="isMobile")
  navbar.desktop-layout(v-else)
  transition(name="fade")
    .overlay(v-show="isOverlayOpen && showOverlay" @click="onOverlayClick")
  transition(name="fade")
    .modal-overlay(v-show="isModalOverlayOpen")
  .app-content(ref="appContent" :class="{ 'is-welcome': isWelcomePage, 'is-search': currentRoute === '/search', 'is-messenger': currentRoute === '/messenger' }")
    .page(:class="{ 'full-width': isFullWidthContent }")
      slot
    ui-footer.footer(v-if="isFooterVisible")
  PopupAcceptCookies(:class="layoutClass")
  Notifications
</template>

<script>
import PopupAcceptCookies from '@/components/ui/PopupAcceptCookies/index.vue'
import { useMainStore } from '@/stores/index'
import Notifications from '@/components/ui/Notifications/index.vue'

const MENU_DURATION = 170

const mainStore = useMainStore()

export default {
  components: { PopupAcceptCookies, Notifications },
  data () {
    return {
      profileRoutes: ['/profile', '/dashboard'],
      footerOpened: false,
    }
  },
  props: {
    layoutStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    isOverlayOpen() {
      const { isOverlayOpen } = useMainStore()
      return isOverlayOpen
    },
    showOverlay() {
      const { showOverlay } = useMainStore()
      return showOverlay
    },
    isModalOverlayOpen() {
      const { isModalOverlayOpen } = useMainStore()
      return isModalOverlayOpen
    },
    layoutClass() {
      return `${this.layoutStyle || ''}`;
    },
    currentRoute() {
      const router = useRouter()
      return router.currentRoute.value.path
    },
    isWelcomePage() {
      return this.$route.path.startsWith('/welcome')
    },
    isFullWidthContent() {
      switch (this.$route.path) {
        case '/welcome':
          return true
        case '/messenger':
          return true
        default:
          return false
      }
    },
    isFooterVisible() {
      switch (this.$route.path) {
        case '/messenger':
          return false
        default:
          break
      }
      
      return !this.isWelcomePage
    },
    isMobile() {
      const mainStore = useMainStore()
      return mainStore.isMobile
    },
  },
  watch: {
    $route () {
      this.$refs.appContent.scrollTop = 0
    },
  },
  mounted () {
    const mainStore = useMainStore()

    if (!this.profileRoutes.includes(this.$route.path)) {
      mainStore.setInitialRoute(this.$route.fullPath)
    }
  },
  methods: {
    onOverlayClick() {
      const mainStore = useMainStore()

      if (mainStore.isAuthFormOpen) {
        mainStore.closeAuthForm()
      }
      if (mainStore.isMenuOpen) {
        mainStore.closeMenu()
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
html,
body,
.layout-container {
  margin: 0;
  padding: 0;
}
.layout-container {
  display: flex;
  max-width: 100vw;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}

.page {
  width: 100%;
  max-width: get-vw(2091);
  display: flex;
  justify-content: center;
  &.full-width {
    max-width: 100%;
  }
}

.footer {
  margin-bottom: get-vw(64);
}

.app-content {
  background-color: #FDFAF8;
  width: calc(100% - get-vw(57) * 2);
  height: 100dvh;
  padding: get-vw(32) get-vw(57);
  right: 0;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.is-messenger {
    padding: 0;
  }

  &.is-search {
    background: $white;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
  }

  &.is-welcome {
    padding: 0;
    width: 100vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    left: get-vw(98);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.mobile-layout {
  position: absolute !important;
  display: none;
}

@include tablet {
  .layout-container {
    grid-template-columns: max-content get-vw(52) get-vw(333) get-vw(333) calc(100vw - get-vw(98)) auto;
  }

  .app-content {
    padding-left: get-vw(15, "md");
    padding-right: get-vw(15, "md");
    padding-top: get-vw(21, "md");
  }

  .page {
    max-width: get-vw(734, "md");
    &.full-width {
      max-width: 100%;
    }
  }
}

@keyframes auth-hide {
  from {
    width: get-vw(313, "md");
  }
  to {
    width: 0vw;
  }
}

.close-profile {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 auto get-vw(30);
  height: get-vw(56);
  width: get-vw(56);

  svg {
    height: get-vw(32);
    width: get-vw(32);

    path {
      fill: $black;
    }
  }
}

@include mobile {
  .desktop-layout {
    display: none;
  }

  .mobile-layout {
    display: block;
    height: auto;
  }

  .app-content {
    width: 100vw;
    margin: get-vw(40, "sm") 0 get-vw(40, "sm") 0;
    height: calc(100dvh - (get-vw(40, "sm") + get-vw(40, "sm")));
    padding: 0;
  }

  .page {
    max-width: 100vw;
  }

  /* .menu-container {
    width: 100%;
    left: 0;
    z-index: 30;
  } */
  @keyframes menu-show {
    from {
      left: -100vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes menu-hide {
    from {
      left: 0;
    }
    to {
      left: -100vw;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@mixin overlay {
  background: rgba($black, 0.55);
  box-shadow: 1px 1px 7px -1px rgba($black, 0.08);
  backdrop-filter: blur(5px);
  height: 100dvh;
  position: absolute;
  width: 100vw;
  z-index: 2;
}

.overlay {
  @include overlay;
}

.modal-overlay {
  @include overlay;
  z-index: 60;
}

@include mobile {
  .mobile-overlay {
    @include overlay;
    padding: get-vw(53, "sm") 0 get-vw(53, "sm") 0;
    height: calc(100dvh - (get-vw(53, "sm") + get-vw(53, "sm")));
  }

  .modal-overlay {
    @include overlay;
    padding: get-vw(53, "sm") 0 get-vw(53, "sm") 0;
    height: calc(100dvh - (get-vw(53, "sm") + get-vw(53, "sm")));
  }

}

.layout-container.is-profile {
  display: flex;
  width: 100%;

  .app-content {
    width: calc(100vw - get-vw(98));
    left: 0;
    right: auto;
    padding: 0;
  }

  .transparent-block {
    flex-grow: 1
  }

  &.menu-hide {
    .menu-container {
      animation-name: profile-menu-hide;
    }
  }

  .menu-container {
    position: fixed;
    left: auto;
    right: get-vw(-651);
    animation-name: profile-menu-show;

    @keyframes profile-menu-show {
      from {
        right: get-vw(-651);
      }
      to {
        right: 0;
      }
    }

    &.hide {
      animation-name: none;
    }

    @keyframes profile-menu-hide {
      from {
        right: 0;
      }
      to {
        right: get-vw(-651);
      }
    }
  }

  @include tablet {
    .menu-container {
      right: get-vw(-313, "md");

      @keyframes profile-menu-show {
        from {
          right: get-vw(-313, "md");
        }
        to {
          right: 0;
        }
      }

      @keyframes profile-menu-hide {
        from {
          right: 0;
        }
        to {
          right: get-vw(-313, "md");
        }
      }
    }
  }

  @include mobile {
    .menu-container {
      right: get-vw(-500, "sm");

      @keyframes profile-menu-show {
        from {
          right: get-vw(-500, "sm");
        }
        to {
          right: 0;
        }
      }

      @keyframes profile-menu-hide {
        from {
          right: 0;
        }
        to {
          right: get-vw(-500, "sm");
        }
      }
    }
  }

  .auth-container {
    .box {
      background: $white;
      animation-name: profile-box-hide;
    }
  }

  .auth-container {
    .box {
      left: auto;
      right: -44vw;
      animation-name: profile-box-show;

      @keyframes profile-box-show {
        from {
          right: -44vw;
        }
        to {
          right: 0;
        }
      }

      @keyframes profile-box-hide {
        from {
          right: 0;
        }
        to {
          right: -44vw;
        }
      }
    }
  }

  .items {
    left: auto;
    right: 4.9vw;
  }

  .auth-container {
    overflow-x: visible;
    width: 24.5vw;
    .box {
      left: auto;
      right: -44vw;
    }

    &--open {
      animation-name: auth-show;
      .box {
        animation-name: profile-auth-box-show;
      }
    }

    &--hide {
      animation-name: auth-hide;
      .box {
        animation-name: profile-auth-box-hide;
      }
    }

    @keyframes profile-auth-box-show {
      from {
        right: -44vw;
      }
      to {
        right: get-vw(-98);
      }
    }

    @keyframes profile-auth-box-hide {
      from {
        right: 0;
      }
      to {
        right: -44vw;
      }
    }
  }

  .navbar {
    &--open {
      animation-name: profile-navbar-open;
    }

    &--hide {
      animation-name: profile-navbar-hide;
    }
  }

  @keyframes profile-navbar-open {
    from {
      left: auto;
    }
    to {
      left: auto;
    }
  }

  @keyframes profile-navbar-hide {
    from {
      left: auto;
    }
    to {
      left: auto;
    }
  }

  .navbar-container {
    &--open {
      animation-name: none;
    }

    &--hide {
      animation-name: none;
    }
  }

  .auth-wrap {
    padding-right: get-vw(59);
    padding-left: get-vw(22);
  }

  .container-menu {
    margin-left: get-vw(60);
  }
}

@include mobile {
  .layout-container.is-profile .app-content {
    width: 100vw;
  }
}

</style>
