<template lang="pug">
.footer-container
  template(v-if="!isMobile")
    hr.hr
    .links-container
      .column(v-for="(column, colIndex) in footerLinks.columns" :key="colIndex")
        a(href="#").header {{ column.header }}
        a(v-for="(link, linkIndex) in column.links" :key="linkIndex" href="#").link {{ link }}
    hr.hr
    .copyright-container
      .copyright-text Copyright {{ currentYear }} Creme Brulee Design Department. All rights reserved.
      .country-text Россия
  template(v-else)
    Accordion
      AccordionPanel.accordion-panel(
        v-for="(item, index) in part.items" 
        :key="index" 
        :title="item.header" 
        :id="index.toString()"
      )
        p(v-for="(links, i) in item.links" :key="i" v-html="links")
    hr.hr
    .copyright-container
      .copyright-text Creme Brulee Design Department. All rights reserved.
      .country-text Россия
</template>

<script setup lang="ts">
import Accordion from '@/components/ui/Accordion/index.vue';
import AccordionPanel from '@/components/ui/AccordionPanel/index.vue';

const mainStore = useMainStore();
const { isMobile } = storeToRefs(mainStore);

const currentYear = new Date().getFullYear();

const footerLinks = reactive({
  columns: [
    {
      header: 'О нас',
      links: ['Кто такие', 'Чего надо', 'Откуда будем', 'Почему мы', 'Почему вы']
    },
    {
      header: 'Сотрудничество',
      links: ['Поработаем', 'Отрекламируем', 'Напишем']
    },
    {
      header: 'Правовые документы',
      links: ['ППРТ', 'Политика конфиденциальности', 'Пользовательское соглашение', 'Правила использования куки']
    },
    {
      header: 'Помощь',
      links: ['Где я?', 'Зачем я здесь?', 'Вы кто такие?', 'Почему я?', 'Когда все это кончится?']
    },
    {
      header: 'Сообщить о проблеме',
      links: ['Поработаем', 'Отрекламируем', 'Напишем']
    }
  ]
})

const part = reactive({
  items: [
    {
      header: 'О нас',
      links: ['Кто такие', 'Чего надо', 'Откуда будем', 'Почему мы', 'Почему вы']
    },
    {
      header: 'Сотрудничество',
      links: ['Поработаем', 'Отрекламируем', 'Напишем']
    },
    {
      header: 'Правовые документы',
      links: ['ППРТ', 'Политика конфиденциальности', 'Пользовательское соглашение', 'Правила использования куки']
    },
    {
      header: 'Помощь',
      links: ['Где я?', 'Зачем я здесь?', 'Вы кто такие?', 'Почему я?']
    },
    {
      header: 'Сообщить о проблеме',
      links: ['Поработаем', 'Отрекламируем', 'Напишем']
    }
  ]
})
</script>

<style lang="scss" scoped>
.footer-container {
  width: get-vw(2091);
}

.hr {
  width: 100%;
  border: none;
  border-bottom: get-vw(2) solid $neutral-200;
}

.links-container {
  display: flex;
  justify-content: space-between;
  margin: get-vw(72) 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.header {
  @include text-t4-semibold;
  text-decoration: none;
  color: $neutral-500;
  margin-bottom: get-vw(24);
}

.link {
  @include text-t4-regular;
  text-decoration: none;
  color: $neutral-500;
  margin-bottom: get-vw(24);
}

.copyright-container {
  display: flex;
  justify-content: space-between;
  color: $neutral-500;
  @include text-t4-regular;
  margin-top: get-vw(92);
}

@include tablet {
  .footer-container {
    width: 100%;
    max-width: get-vw(734, "md");
  }

  .hr {
    border-bottom: get-vw(1, "md") solid $neutral-200;
  }

  .links-container {
    margin: get-vw(49, "md") 0;
  }

  .header {
    @include text-t8-semibold-s("md");
    margin-bottom: get-vw(11, "md");
  }

  .link {
    @include text-t8-regular-s("md");
    margin-bottom: get-vw(11, "md");
  }

  .copyright-container {
    @include text-t8-regular-s("md");
    margin-top: get-vw(48, "md");
  }
}

@include mobile {
  .footer-container {
    max-width: calc(100% - get-vw(32, "sm"));
    padding: get-vw(16, "sm");
  }

  .accordion-panel {
    color: $neutral-400;
    border-bottom: get-vw(1, "sm") solid $neutral-200;
    @include text-t8-semibold-s;
    padding-bottom: get-vw(13, "sm");

    &:deep(.title) {
      @include text-t8-regular-s;
    }

    &:deep(.content) {
      margin-top: 0;
    }
  }

  :deep(.accordion-panel svg path) {
    stroke: $neutral-400;
  }

  .hr {
    width: 100%;
    border: none;
    border-bottom: get-vw(1, "sm") solid $neutral-200;
    margin-top: 0;
    margin-bottom: get-vw(13, "sm");
  }

  .copyright-container {
    @include text-t8-regular-s;
    color: $neutral-400;
    margin-top: 0;
  }
}
</style>
  
  