<script setup lang="ts">
import Authorization from '@/components/Authorization.vue';
import { storeToRefs } from 'pinia';
const mainStore = useMainStore()
const {
  toggleSearch,
  toggleMenu,
  toggleProfile,
  closeProfile,
  toggleAuthForm,
  closeAuthForm,
} = mainStore
const {
  isSearchOpen,
  isProfileOpen,
  loggedIn,
  isAuthFormOpen,
  isMenuOpen
} = storeToRefs(mainStore)

const authorizationRef = ref<typeof Authorization | null>(null)

onMounted(() => {
  if (isAuthFormOpen.value) {
    authorizationRef.value?.focus()
  }
})

watch(() => isAuthFormOpen, (value) => {
  if (loggedIn) {
    return
  }

  if (value) {
    authorizationRef.value.focus()
  }
})

watchEffect(() => {
  const loggedIn = mainStore.loggedIn
  if (loggedIn) {
    nextTick(() => {
      isAuthFormOpen && closeAuthForm()
    })
  }
})
</script>

<template lang="pug">
.navbar-container(:class="{'navbar-container--open': isAuthFormOpen, 'navbar-container--hide': isAuthFormOpen === false }")
  .auth-container(v-if="!loggedIn" :class="{ 'auth-container--open': isAuthFormOpen, 'auth-container--hide': isAuthFormOpen === false }")
    .box
      Authorization.auth-screen.auth-screen--disabled(ref="authorizationRef")
  .navbar(:class="{ 'navbar--open': isAuthFormOpen === false, 'navbar--hide': isAuthFormOpen }")
    .top
      navbar-search
      navbar-logo
      navbar-menu-button
      navbar-chat
      navbar-profile

      //-.shop
      navbar-hot-button
    .bottom
      navbar-footer
  .menu-container(
    :class="{ 'active': isMenuOpen, 'hide': isMenuOpen === false }"
    ref="menuContainerRef"
  )
    ui-menu.menu
</template>

<style lang="scss" scoped>
$menu-duration: 0.17s;
.auth-container {
  width: 0vw;
  z-index: 31;
  background-color: $white;
  position: fixed;
  overflow-x: hidden;
  height: 100%;

  @keyframes auth-show {
    from {
      width: 0vw;
    }
    to {
      width: get-vw(591);
    }
  }

  @keyframes auth-hide {
    from {
      width: get-vw(591);
    }
    to {
      width: 0vw;
    }
  }

  .box {
    width: get-vw(591);
    position: absolute;
    left: get-vw(-591);
    height: 100%;

    @keyframes auth-box-show {
      from {
        left: get-vw(-591);
      }
      to {
        left: 0;
      }
    }

    @keyframes auth-box-hide {
      from {
        left: 0;
      }
      to {
        left: get-vw(-591);
      }
    }
  }
  .page {
    width: 100%;
    min-height: calc(90vh - (100vw * 0.2605));
  }

  &--open {
    animation-duration: 420ms;
    animation-name: auth-show;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    .box {
      animation-duration: 440ms;
      animation-name: auth-box-show;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
  }

  &--hide {
    animation-duration: 440ms;
    animation-name: auth-hide;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    .box {
      animation-duration: 440ms;
      animation-name: auth-box-hide;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
  }
}

.navbar-container {
  max-width: 64px;
  z-index: 5;
  width: round(get-vw(98), 10px);
  min-width: round(get-vw(98), 10px)
}

.menu-container {
  position: fixed;
  left: get-vw(-651);
  z-index: 2;
  background-color: $white;
  height: 100dvh;
  top: 0;
  display: flex;
  &::before {
    content: '';
    width: get-vw(115);
    height: 100%;
    max-width: calc(64px + get-vw(17));
  }

  .menu {
    margin-left: get-vw(18);
    width: get-vw(558);  
  }

  @keyframes menu-show {
    from {
      left: get-vw(-651);
    }
    to {
      left: 0;
    }
  }

  @keyframes menu-hide {
    from {
      left: 0;
    }
    to {
      left: get-vw(-651);
    }
  }

  &.active {
    animation-duration: $menu-duration;
    animation-name: menu-show;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    overflow-y: auto;
  }

  &.hide {
    animation-duration: $menu-duration;
    animation-name: menu-hide;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }
}

.navbar {
  position: relative;
  height: 100vh;
  width: 57%;
  padding-left: 21.5%;
  padding-right: 21.5%;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: $white;

  .top {
    display: flex;
    flex-direction: column;
    gap: get-vw(29);
  }

  @keyframes navbar-open {
    from {
      left: get-vw(591);
    }
    to {
      left: 0;
    }
  }

  @keyframes navbar-hide {
    from {
      left: 0;
    }
    to {
      left: get-vw(591);
    }
  }

  &--open {
    animation-duration: 440ms;
    animation-name: navbar-open;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }

  &--hide {
    animation-duration: 440ms;
    animation-name: navbar-hide;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }
}

@include tablet {
  .navbar {
    .top {
      gap: unset;
    }
  }

  .navbar-container {
    width: get-vw(70, "md");
    max-width: unset;
  }
  .auth-container .box {
    width: get-vw(313, "md");
  }
  @keyframes auth-show {
    from {
      width: 0vw;
    }
    to {
      width: get-vw(313, "md");
    }
  }

  @keyframes auth-hide {
    from {
      width: get-vw(313, "md");
    }
    to {
      width: 0vw;
    }
  }

  @keyframes navbar-show {
    from {
      left: 30.5vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes navbar-hide {
    from {
      left: 0;
    }
    to {
      left: 30.5vw;
    }
  }

  .menu-container {
    width: 34vw;
    left: -34vw;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &::before {
      content: '';
      width: get-vw(70, "md");
      height: 100%;
      max-width: unset;
    }
  }

  @keyframes menu-show {
    from {
      left: -34vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes menu-hide {
    from {
      left: 0;
    }
    to {
      left: -34vw;
    }
  }
}
</style>